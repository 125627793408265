import { useOrderFormStore } from '@/stores/forms/orderFormStore';

import useMerchApiEndpoints from '@/composables/api/useMerchApiEndpoints';
import useAutomaticRedirect from '@/composables/helpers/automaticRedirect';
import useCreateDefaultsFromApi from '@/composables/api/useCreateDefaultsFromApi';

const handleApiRedirect = async (routerRef) => {
  const orderStore = useOrderFormStore();
  const { getApiOrder, getPAPIOrder } = useMerchApiEndpoints();
  const { redirectForApiOrders } = useAutomaticRedirect();
  const {
    isAnApiOrder, isPapiOrder, checkApiForOrderTypeAndSetMode, checkApiToSetPaymentType,
  } = useCreateDefaultsFromApi();

  if (isAnApiOrder()) {
    const merchRes = await (isPapiOrder()
    ? getPAPIOrder()
    : getApiOrder());

    // NOTE: setMerchApiData, checkApiForOrderTypeAndSetMode and
    // checkApiToSetPaymentType order should not be changed
    orderStore.setMerchApiData(merchRes.data);
    await checkApiForOrderTypeAndSetMode();
    checkApiToSetPaymentType();

    const isAnApplePayOrder = await orderStore.checkUserApplePaySession();

    if ((!!isAnApplePayOrder && orderStore.canSkipOrderPageFromApiData)
      || orderStore.canSkipOrderPageFromApiData
    ) {
      await redirectForApiOrders(routerRef);
    }
  }
};

export {
	handleApiRedirect,
};
