<template>
  <div class="toast" :class="toastClass" v-if="visible">
    <div class="toast__content">
      <div class="toast__message">
        {{ message }}
      </div>

      <CrossIcon class="toast__close" :iconColor="computedColor" @click="close" v-if="showClose" />
    </div>
  </div>
</template>

<script setup>
import {
 ref, computed, onMounted, onUnmounted,
} from 'vue';
import { useToast } from '@/composables/ui/alerts/useToast';
import CrossIcon from '@/components/icons/Cross.vue';

const { removeToast } = useToast();

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'info',
    validator: (value) => {
      const options = [
        'info',
        'success',
        'error',
        'warning',
      ];
      return options.includes(value);
    },
  },
  duration: {
    type: Number,
    default: 3000,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  position: {
    type: String,
    default: 'top-right',
    validator: (value) => {
      const options = [
        'top-left',
        'top-right',
        'top-center',
        'bottom-left',
        'bottom-right',
        'bottom-center',
      ];
      return options.includes(value);
    },
  },
  id: {
    type: Number,
    default: 0,
  },
});

const visible = ref(false);
let timer = null;

const positionClass = computed(() => ({
  [`toast--${props.position}`]: true,
}));

const toastClass = computed(() => ({
  [`toast--${props.type}`]: true,
  ...positionClass.value,
}));

const close = () => {
  visible.value = false;
  if (timer) {
    clearTimeout(timer);
    removeToast(props.id);
    timer = null;
  }
};

onMounted(() => {
  visible.value = true;
  if (props.duration > 0) {
    timer = setTimeout(close, props.duration);
  }
});

onUnmounted(() => {
  if (timer) {
    clearTimeout(timer);
  }
});
</script>

<style lang="scss" scoped>
.toast {
  padding: 15px 20px;
  border-radius: 5px;
  color: white;
  z-index: 1000;
  max-width: 80%;
  display: flex;
  align-items: center;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__message {
    font-size: $font-body;
  }

  &__close {
    background: none;
    border: none;
    cursor: pointer;
    width: 18px;
    height: 18px;
    color: inherit;
    margin-left: 15px;
    border-radius: 50%;
    display: flex;

    &::before {
      transition: opacity 300ms ease-in-out;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  &--info {
    background-color: #007bff;
  }

  &--success {
    background-color: #28a745;
  }

  &--error {
    background-color: #dc3545;
  }

  &--warning {
    background-color: #ffc107;
  }

  &--top-left {
    position: fixed;
    top: 20px;
    left: 20px;
  }

  &--top-right {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  &--bottom-left {
    position: fixed;
    bottom: 20px;
    left: 20px;
  }

  &--bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  &--top-center {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &--bottom-center {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
