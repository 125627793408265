import { i18n } from '@/packages/i18n';

import { useGlobalStore } from '@/stores/globalStore';
import { useNotificationStore } from '@/stores/components/notificationStore';

import useMerchApiEndpoints from '@/composables/api/useMerchApiEndpoints';
import handleDirectVisit from '@/router/middleware/handleDirectVisit';
import useLocalStorage from '@/composables/storage/useLocalStorage';
import { trackClarityTag } from '@/composables/tracking/useTracking';

const trackMerchSettings = (settings) => {
  const trackedSettings = {
    VALIDATE_EMAIL: settings.VALIDATE_EMAIL,
    VALIDATE_MSISDN: settings.VALIDATE_MSISDN,
    allowDirectVisit: settings.allowDirectVisit,
    readOnlyWalletAddress: settings.readOnlyWalletAddress,
    readOnlyAmounts: settings.readOnlyAmounts,
    usesCustodialWallet: settings.usesCustodialWallet,
    referredWalletAddressIsEditable: settings.referredWalletAddressIsEditable,
    defaultCoin: settings.defaultCoin,
    shouldAutoRedirectToMerchant: settings.shouldAutoRedirectToMerchant,
  };

  Object.entries(trackedSettings).forEach(([key, value]) => {
    if (value === null) return;
    trackClarityTag(key, value);
  });
};

export default () => {
  const { getMerchSettings, getNotificationSettings } = useMerchApiEndpoints();

  const checkUrlForMerchUrls = (route) => {
    const globalStore = useGlobalStore();
    const { bindLocalStorage } = useLocalStorage();
    const { returnUrl } = route.query;
    const { t } = i18n.global;

    if (returnUrl) {
      globalStore.global.merchant.returnUrl = returnUrl;
      globalStore.global.merchant.returnMessage = `${t('merchantSettings.label.returnMessage')} ${globalStore.global.merchant.name}`;
      globalStore.global.merchant.returnUrlIsFromQuery = true;
      // to account for for 3rd party redirects
      bindLocalStorage('merchReturnUrl', globalStore.global.merchant.returnUrl);
    }
  };

  const getMerchantSettings = async (route) => {
    const globalStore = useGlobalStore();
    let settings = await getMerchSettings();
    settings = settings.data;
    globalStore.setMerchantSettings(settings);
    globalStore.setExpressCheckoutEnabled(settings?.expressPaymentEnabled);
    checkUrlForMerchUrls(route);
    handleDirectVisit();
    trackMerchSettings(settings);
  };

  const getNotifications = async () => {
    const notificationStore = useNotificationStore();
    const {
      data: { order, orderStatus },
    } = await getNotificationSettings();
    notificationStore.setBanner(order, orderStatus);
  };

  return {
    getMerchantSettings,
    getNotifications,
  };
};
