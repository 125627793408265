import { useGlobalStore } from '@/stores/globalStore';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

export default () => {
  const globalStore = useGlobalStore();
  const orderStore = useOrderFormStore();

  const defaultData = {
    urlData: {},
    orderMode: null,
    disableUIKeys: {},
    areDefaultsFromUrl: false,
  };

  const checkAndMutateIfFiatAndCoinTypesAreValid = (
    key,
    value,
    fiatInputs,
    coinInputs,
  ) => {
    if (key === 'fiatType' && !fiatInputs.includes(value)) {
      const [newDefaultFiatValue] = fiatInputs;
      return newDefaultFiatValue;
    }

    if (key === 'coinType' && !coinInputs.includes(value)) {
      const [newDefaultCoinValue] = coinInputs;
      return newDefaultCoinValue;
    }

    return '';
  };

  /**
   * Method binds business logic to push to the Vue form ref Object
   * and update the store using updateValue action.
   * @param {Object} data
   * @param {Ref Object} form
   * @param {Object} store
   * @returns {}
   * */
  const updateStoreByMerchApiData = async (
    merchApiData,
    form,
    store,
    fiatInputs = [],
    coinInputs = [],
  ) => {
    if (merchApiData.fiatAmount === null && merchApiData.coinAmount === null) {
      merchApiData.fiatAmount = 100;
    }

    const orderData = Object.entries(merchApiData);
    const keysToRelatedToOrder = Object.keys(store.form);
    const keysRelatedToOtp = Object.keys(store.otpData);

    orderData.forEach(([key, value]) => {
      if (keysRelatedToOtp.includes(key) && value) {
        store.otpData[key] = value;
        store.updateValue(key, value);
      }

      if (!keysToRelatedToOrder.includes(key) || !value) {
        if (key === 'account_reference') {
          globalStore.setCustomerReference(value);
        }
        if (key === 'lockFiat') {
          store.form.lockFiat.value = value;
        }
        return;
      }

      if (key === 'fiatType' || key === 'coinType') {
        const newUnSupportedDefaultValue = !orderStore.canSkipOrderPageFromApiData
        && checkAndMutateIfFiatAndCoinTypesAreValid(
            key,
            value,
            fiatInputs,
            coinInputs,
          );

        if (newUnSupportedDefaultValue) {
          store.updateValue(key, newUnSupportedDefaultValue);
          form.value[key] = newUnSupportedDefaultValue;
          store.form[key].fromApi = true;
          return;
        }
      }

      const skipValidations = orderStore.canSkipOrderPageFromApiData;

      store.form[key].fromApi = true;
      store.updateValue(key, value, skipValidations);
      form.value[key] = value;
    });
  };

  /**
   * Sifts through the api order data to find relevant keys' values
   * filled and if so, then to provide a Boolean for that field to be
   * disabled, and set within the reduced object to be returned.
   *
   * @param {Array} orderData (Array passed through Object.entries)
   * @param {Function} callback
   * @returns {Object}
   * */

  const disableUiByApi = (merchApiData) => {
    const keysRelatedToApiToDisableUiBy = [
      'coinType',
      'walletAddress',
      'walletAddressTag',
      'blockchain',
    ];

    if (globalStore?.global?.merchant?.readOnlyAmounts) {
      keysRelatedToApiToDisableUiBy.push('coinAmount');
      keysRelatedToApiToDisableUiBy.push('fiatAmount');
      keysRelatedToApiToDisableUiBy.push('fiatType');
    }
    const orderData = Object.entries(merchApiData);

    return orderData.reduce((acc, [key, value]) => {
      if (
        !keysRelatedToApiToDisableUiBy.includes(key)
        || (!['coinAmount', 'fiatAmount'].includes(key) && !value)
      ) {
        return acc;
      }

      if (
        globalStore.getMerchantSettings?.referredWalletAddressIsEditable
        && key.includes('walletAddress')
      ) {
        return acc;
      }

      acc[key] = true;
      return acc;
    }, {});
  };

  return {
    updateStoreByMerchApiData,
    disableUiByApi,
    defaultData,
  };
};
