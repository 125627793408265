export default function useSessionStorage() {
  const checkFormValidatorBinding = (data, checkedData) => {
    if (!data) {
      return checkedData;
    }

    Object.keys(data).forEach((field) => {
      if (
        !data[field]
        || typeof data[field] !== 'object'
        || !Object.getOwnPropertyDescriptor(data[field], 'validator')
      ) {
        return;
      }

      const { validator } = data[field];

      if (!validator) {
        return;
      }
      if (checkedData[field]) {
        checkedData[field].validator = validator;
      }
    });

    return checkedData;
  };

  const checkSessionStorage = (key, data) => {
    const storageRef = sessionStorage.getItem(key);
    const checkedData = storageRef === null ? data : JSON.parse(storageRef);
    return checkFormValidatorBinding(data, checkedData);
  };

  const checkSessionStorageByKeys = (key1, key2, defaultData) => {
    const storageRef = JSON.parse(sessionStorage.getItem(key1));
    const storageRefDepthOne = storageRef && storageRef[key2];
    const checkedData = storageRefDepthOne || defaultData[key2];

    return checkedData;
  };

  const bindSessionStorage = (key, data) => {
    if (data !== undefined) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  };

  const clearAllStorage = () => {
    sessionStorage.clear();
  };

  const checkPaymentSessionStorage = (store, defaultData) => {
    const paymentData = checkSessionStorageByKeys(store, 'payment', defaultData);
    const { required, ...rest } = paymentData;

    return {
      ...{
        required: true,
      },
      ...rest,
    };
  };

  const removeSessionStorageByKey = (key) => {
    if (!sessionStorage.getItem(key)) {
      return;
    }

    sessionStorage.removeItem(key);
  };

  return {
    checkSessionStorage,
    bindSessionStorage,
    clearAllStorage,
    checkSessionStorageByKeys,
    checkPaymentSessionStorage,
    removeSessionStorageByKey,
  };
}
