<template>
  <transition-group name="toast-list">
    <ToastNotification
      v-for="toast in toasts"
      :key="toast.id"
      v-bind="toast"
      @close="removeToast(toast.id)"
    />
  </transition-group>
</template>

<script setup>
import { useToast } from '@/composables/ui/alerts/useToast';
import ToastNotification from '@/components/ui/alerts/ToastNotification.vue';

const { toasts, removeToast } = useToast();
</script>

<style scoped>
.toast-list-enter-active,
.toast-list-leave-active {
  transition: all 0.3s ease;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
