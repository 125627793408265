import { nextTick } from 'vue';
import { useGlobalStore } from '@/stores/globalStore';
import useModalActions from '@/composables/store/modal/useModalActions';

export default () => {
  const globalStore = useGlobalStore();
  const { showWalletModal } = useModalActions();

  // copy pasted from useCreateDefaultsFromUrl due to a recursion import bug
  const fieldsRelatedToUrlOrder = [
    'fiatAmount',
    'fiatType',
    'coinAmount',
    'coinType',
    'orderType',
    'walletAddress',
  ];

  const isAnUrlOrder = () => {
    const { searchParams } = new URL(window.location.href);
    const searchParamsSize = [...searchParams].length;
    const searchParamsIsNotAnApiOrder = !searchParams.has('id') && !searchParams.has('initId');
    const urlParamsRelatedToOrder = fieldsRelatedToUrlOrder.some(
      (field) => searchParams.has(field),
    );

    return (
      urlParamsRelatedToOrder
      && searchParamsIsNotAnApiOrder
      && searchParamsSize > 0
    );
  };

  const isAnApiOrder = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('id') || params.has('initId');
  };

  const handleWalletModalFlow = (handleFormSuccessCallback) => {
    const isMobile = window.innerWidth <= 768;
    const alignContentForModal = isMobile ? 'end' : 'center';

    showWalletModal({
      alignment: {
        display: 'grid',
        'justify-content': 'center',
        'align-content': alignContentForModal,
      },
      primaryBtnAction() {
        handleFormSuccessCallback();
      },
      secondaryBtnAction() {
        nextTick(() => {
          document.getElementById('walletAddress').focus();
          globalStore.setIsFormLoading(false);
        });
      },
    });
  };

  // don't show for API orders, show for refferal URL or direct visit orders
  const isWalletConfirmationModalRequired = () => (!isAnApiOrder() || isAnUrlOrder())
  && globalStore.getMerchantSettings?.referredWalletAddressIsEditable;

  return {
    handleWalletModalFlow,
    isWalletConfirmationModalRequired,
  };
};
