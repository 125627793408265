/* eslint-disable import/prefer-default-export */
import { ref } from 'vue';

const toasts = ref([]);

export function useToast() {
  const addToast = (toast) => {
    toasts.value.push({
      id: Date.now(),
      ...toast,
    });
  };

  const removeToast = (id) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  };

  return { toasts, addToast, removeToast };
}
