import { useGlobalStore } from '@/stores/globalStore';

import useErrorHandling from '@/composables/api/useErrorHandling';
import appConstants from '@/composables/helpers/appConstants';
import useCreateDefaultsFromApi from '@/composables/api/useCreateDefaultsFromApi';

const generateErrorHtml = (merch) => {
    const { name, returnUrl } = merch;
    const errorHtml = `<p>
        To place an order with Banxa on ${name}, please<br />
        visit ${name}'s
        <a href="${returnUrl}" rel="noopener noreferrer">Buy Crypto</a>
        page directly.<br />
      </p>

      <p>
        Do you have any questions?
        <br />
        Our friendly 24/7 support team are always happy to
        <nobr>assist you.</nobr>
        <br />
        Simply reach out to us under:
        <a
          rel="noopener noreferrer"
          style="text-decoration: underline"
          href=mailto:${appConstants.SUPPORT_EMAIL}
          >${appConstants.SUPPORT_EMAIL}</a
        >
      </p>`;

      return errorHtml;
  };

  const handleDirectVisit = () => {
    const globalStore = useGlobalStore();
    const currentUrl = new URL(window.location);
    const splitUrl = currentUrl.pathname.split('/');
    const { handleErrorInSpaFlow } = useErrorHandling();
    const { isAnApiOrder } = useCreateDefaultsFromApi();
    const { merchant } = globalStore.global;

    // user might come back for with a link even if they're from API
    const urlsToSkipDirectVist = [
      'status',
      'coindeposit',
      'recovery',
      'callback', /* for payment callbacks */
    ];

    // user might come back for with a link even if they're from API
    if (
      isAnApiOrder()
      || merchant?.allowDirectVisit
      || urlsToSkipDirectVist.some((url) => splitUrl.includes(url))
    ) {
      return;
    }

    handleErrorInSpaFlow('dontAllowDirectVisit', generateErrorHtml(merchant));
  };

export default handleDirectVisit;
