// appConstants.js

const SUPPORT_EMAIL = 'support@banxa.com';

const BANXA_URLS = {
  terms: 'https://banxa.com/terms-of-use/',
  privacy: 'https://banxa.com/privacy-and-cookies-policy/',
  biometric: 'https://banxa.com/biometric-privacy-disclosure-and-consent',
	dueDiligence: 'https://banxa.com/risk-summary/',
};

const AXNAB_URLS = {
  terms: 'https://axnab.com/terms-of-use/',
  privacy: 'https://axnab.com/privacy-and-cookies-policy/',
  biometric: 'https://axnab.com/biometric-privacy-disclosure-and-consent',
	dueDiligence: 'https://axnab.com/due-diligence',
};

export default { SUPPORT_EMAIL, BANXA_URLS, AXNAB_URLS };
