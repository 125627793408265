import { i18n } from '@/packages/i18n';
import { useToast } from '@/composables/ui/alerts/useToast';
import { useProgressStore } from '@/stores/progressStore';

const keys = ['verification', 'payment', 'identity'];

export default () => {
  const { addToast } = useToast();

/**
 * Checks the progress status of a given step and displays a success toast
 * notification if the step is not complete.
 *
 * @param {string} step - The step to check the progress for. It should be a key
 * corresponding to a step in the progress store (which is from the localStorage)
 */

  const checkProgressByStep = (step) => {
    const { t } = i18n.global;
    const store = useProgressStore();
    const isComplete = Object.hasOwnProperty.call(store.progress, step) && store.progress[step] === 'COMPLETE';
    const stepCompletedMsg = `${t(`SuccessModal.label.${String(step).toLowerCase()}`)
    } ${t('SuccessModal.label.complete')}`;

    if (!isComplete) {
      addToast({
        message: stepCompletedMsg,
        type: 'success',
        duration: 3000,
      });
      store.updateToComplete(step);
    }
  };

  const updateToComplete = (keysToUpdate) => {
    keysToUpdate.forEach((key) => {
      setTimeout(() => checkProgressByStep(key), 2000);
    });
  };

  const updateToPending = (key) => {
    const store = useProgressStore();
    store.updateToPending(key);
  };

  const updateToIncomplete = (key) => {
    const store = useProgressStore();
    store.updateToIncomplete(key);
  };

  const resetAllProgress = () => {
    const store = useProgressStore();
    keys.forEach((key) => {
      store.updateToIncomplete(key);
    });
  };

  return {
    updateToComplete,
    updateToPending,
    updateToIncomplete,
    resetAllProgress,
  };
};
