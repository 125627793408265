import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';

import { useGlobalStore } from '@/stores/globalStore';
import { useErrorStore } from '@/stores/components/errorStore';
import { useIdentityStore } from '@/stores/identityStore';
import { useOrderFormStore } from '@/stores/forms/orderFormStore';

import useDecimalHelpers from '@/composables/helpers/useDecimalHelpers';
import useWalletModal from '@/composables/ui/useWalletModal';
import useOrderCreation from '@/composables/api/useOrderCreation';
import { expressCheckoutEnabled } from '@/composables/router/handleExpressCheckout';

export default function useAutomaticRedirect() {
  const globalStore = useGlobalStore();

  const { convertCoinAmountToDecimals } = useDecimalHelpers;
  const orderData = ref({});
  const covertedCoinAmount = computed(
    () => convertCoinAmountToDecimals(orderData.value.coinAmount, 8),
  );

  const formatRedirectUrl = (orderId) => {
    const {
      nativeSellDetails,
      walletAddress,
      blockchain: { code: blockchainCode } = {},
      coinType,
      walletAddressTag,
    } = orderData.value || {};

    const coinAmount = covertedCoinAmount.value;

    const redirectUrl = new URL(nativeSellDetails?.redirectSellUrl);

    const query = new URLSearchParams(redirectUrl.search);
    const params = {
      orderId,
      providerWalletAddress: walletAddress,
      blockchain: blockchainCode,
      coinType,
      coinAmount,
      providerWalletAddressTag: walletAddressTag,
      customerWalletAddress: nativeSellDetails?.customerWalletAddress,
    };

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        query.set(key, value);
      }
    });

    redirectUrl.search = query.toString();

    return redirectUrl.toString();
  };

  const redirectToErrorPage = () => {
    const router = useRouter();
    const { t } = useI18n();
    const errorStore = useErrorStore();
      const error = {
          code: '50422',
          message: t('CoinDepositForm.error.sellWalletType'),
      };

      errorStore.$state.error = { ...error };

      router.push({
        name: 'error',
      });
  };

  /**
   * Redirect the user automatically to the specified URL
   */
  const redirectAutomatically = (orderId, orderDataProvided) => {
    orderData.value = orderDataProvided;
    const { redirectSellUrl } = orderData.value.nativeSellDetails;

    if (!redirectSellUrl) {
      // If order data provided has not sufficient data then redirect to error page
      redirectToErrorPage();
      return;
    }

    const redirectUrl = formatRedirectUrl(orderId, orderData);
    if (globalStore.getIsInIFrame) {
      window.parent.location = redirectUrl;
    }
    window.location.replace(redirectUrl);
  };

  const setMerchDefaultsBeforeRedirect = () => new Promise((resolve) => {
    const orderStore = useOrderFormStore();
    const form = ref({});
    const { updateStoreByMerchApiData } = useOrderCreation();
    updateStoreByMerchApiData(orderStore.merchApiOrderData, form, orderStore.getActiveStore);
    orderStore.setPspTypeCode(orderStore.merchApiOrderData.paymentType.toLowerCase());

    const isMobileRequired = orderStore.filterPaymentDataByActivePaymentName?.require_mobile
    ?? false;
    orderStore.setPspRequireMobile(isMobileRequired);

    resolve();
  });

  const redirectForApiOrders = async (routerRef) => {
    const route = useRoute();
    const identityStore = useIdentityStore();
    const orderStore = useOrderFormStore();
    const { isWalletConfirmationModalRequired } = useWalletModal();

    const isAnApplePayOrderOrSessionDoesntExist = await orderStore.checkUserApplePaySession();
    if ((isAnApplePayOrderOrSessionDoesntExist)
      || !orderStore.canSkipOrderPageFromApiData
      || isWalletConfirmationModalRequired()
    ) {
      return;
    }

    setMerchDefaultsBeforeRedirect().then(async () => {
      const { redirectTo } = orderStore.merchApiOrderData;

      if (expressCheckoutEnabled() && redirectTo?.toLowerCase() === 'payment') {
        orderStore.setExpressCheckoutEnabled(true);
        routerRef.push('/payment');
        return;
      }

      if (redirectTo.indexOf('verification') >= 0) {
        routerRef.push(`/${redirectTo}`);
        return;
      }

      await identityStore.fetchIdentityDocuments();
      identityStore.fetchNextIdentityRoute(route).then((nextRoute) => {
        routerRef.push(nextRoute);
      });
    });
  };

  return {
    redirectAutomatically,
    redirectForApiOrders,
  };
}
