import { useOrderFormStore } from '@/stores/forms/orderFormStore';

import useOrderEndpoints from '@/composables/api/useOrderEndpoints';
import useOrderCreation from '@/composables/api/useOrderCreation';

export default () => {
  const orderStore = useOrderFormStore();
  const { initOrder } = useOrderEndpoints();
  const { defaultData, disableUiByApi } = useOrderCreation();

  const forceStringType = (value) => (value && typeof value === 'string' ? value : String(value));

  const isAnApiOrder = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('id') || params.has('initId');
  };

  const isPapiOrder = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('initId');
  };

  const checkApiForOrderTypeAndSetMode = async () => {
    const checkOrderTypeAndSetMode = (data) => {
      if (
        !Object.getOwnPropertyDescriptor(data, 'orderType')
        && data.orderType !== 'CRYPTO-BUY'
        && data.orderType !== 'CRYPTO-SELL'
      ) {
        console.error('No order type in api data: ', data);
        return;
      }

      const orderType = data.orderType.toUpperCase().replace('CRYPTO-', '');

      defaultData.orderMode = orderType;
      orderStore.setActiveMode(orderType);
    };

    if (!isAnApiOrder()) {
      return defaultData;
    }

    const merchOrderRes = orderStore.merchApiOrderData;

    if (isPapiOrder()) {
      checkOrderTypeAndSetMode(merchOrderRes);
      defaultData.urlData = merchOrderRes;
      defaultData.disableUIKeys = disableUiByApi(merchOrderRes);
      defaultData.areDefaultsFromUrl = true;

      const params = new URLSearchParams(window.location.search);
      if (params.has('subscriptionId')) {
        defaultData.urlData = {
          ...defaultData.urlData,
          subscriptionId: params.get('subscriptionId'),
        };
      }
      return defaultData;
    }

    if (merchOrderRes?.walletAddressTag) {
      merchOrderRes.walletAddressTag = forceStringType(
        merchOrderRes.walletAddressTag,
      );
      orderStore.getActiveStore.setRequiredFieldByKey(
        'walletAddressTag',
        true,
      );
    }

    checkOrderTypeAndSetMode(merchOrderRes);
    defaultData.urlData = merchOrderRes;
    defaultData.disableUIKeys = disableUiByApi(merchOrderRes);
    defaultData.areDefaultsFromUrl = true;

    return defaultData;
  };

  const checkApiForNFTOrder = async () => {
    if (!isAnApiOrder()) {
      return defaultData;
    }

    await initOrder();
    const merchOrderRes = orderStore.merchApiOrderData;
    if (!merchOrderRes) {
      return defaultData;
    }
    if (merchOrderRes?.walletAddressTag) {
      merchOrderRes.walletAddressTag = forceStringType(
        merchOrderRes.walletAddressTag,
      );
    }

    defaultData.urlData = merchOrderRes;
    defaultData.areDefaultsFromUrl = true;
    defaultData.orderMode = 'NFT-BUY';
    defaultData.disableUIKeys = {
      fiatAmount: true,
      fiatType: !!merchOrderRes.lockFiat,
      ...disableUiByApi(merchOrderRes),
    };
    return defaultData;
  };

  const checkApiToSetPaymentType = () => {
    if (!isPapiOrder()) return; // Early return if not a papi order

    const merchOrderRes = orderStore.merchApiOrderData;
    if (!merchOrderRes) return;

    const { payment, paymentType } = merchOrderRes;

    if (payment) orderStore.setActiveModePaymentProvider(payment);
    if (paymentType) orderStore.setPspTypeCode(paymentType.toLowerCase());
  };

  return {
    isAnApiOrder,
    isPapiOrder,
    checkApiForOrderTypeAndSetMode,
    checkApiToSetPaymentType,
    checkApiForNFTOrder,
  };
};
